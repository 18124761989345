



import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as echarts from "echarts";
import { LineOptions } from "./chart_config";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";
import { DailyUsage } from "@/models/Statistics";

@Component
export default class LineChart extends Vue {
  @Prop({ required: true })
  public data!: DailyUsage[];

  @Prop({ required: false, default: false })
  public title!: string;

  drawChart(data: DailyUsage[]): void {
    const ec = echarts as any;
    const container = document.getElementById(this.chartId);
    let chart = echarts.getInstanceByDom(
      document.getElementById(this.chartId) as HTMLElement
    );
    if (!chart) {
      chart = ec.init(container);
    }
    chart?.setOption(LineOptions(this.title, data, this.chartId), true);
    window.onresize = (): void => {
      chart?.resize();
    };
  }
  get chartId(): string {
    return this.title.toLocaleLowerCase().replace(/\s/g, "_");
  }
  mounted(): void {
    this.drawChart(this.data);
    AppEventBus.$on(AppEventBusEvents.redrawGraphEvent, (s: any) => {
      this.drawChart(s);
    });
  }
}
