import Vue from "vue";

export enum AppEventBusEvents {
  changeStatusEvent = "changeStatusEvent",
  deleteDocumentEvent = "deleteDocumentEvent",
  drawdownEvent = "drawdownEvent",
  redrawGraphEvent = "redrawGraphEvent",

}

export const AppEventBus = new Vue();
