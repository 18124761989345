





































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import LineChart from "@/components/echarts/LineChart.vue";
import { getNextMonth } from "@/helpers/date_util";
import agent from "@/helpers/agent";
import UsageStatistics, { DailyUsage } from "@/models/Statistics";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";

@Component({ components: { LineChart } })
export default class LineChartView extends Vue {
  data: UsageStatistics = {
    totalCreatedApplications: 0,
    totalSubmittedApplications: 0,
    totalReviewedApplications: 0,
    totalApprovedApplications: 0,
    totalRejectedApplications: 0,
    totalPortfolioApplications: 0,
    dailyUsages: [],
  };
  startDate = new Date(2022, 6, 0);
  endDate = new Date();

  created() {
    agent.Statistics.usage(this.startDate, this.endDate).then((stats) => {
      this.data = stats;
      AppEventBus.$emit(
        AppEventBusEvents.redrawGraphEvent,
        this.data.dailyUsages
      );
    });
  }
}
