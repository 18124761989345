import { DailyUsage } from "@/models/Statistics";
import { format } from "date-fns";
import * as echarts from "echarts";

export function LineOptions(
  title: string,
  data: DailyUsage[],
  chartId: string
) {
  const created = data.map((i) => i.created);
  const submitted = data.map((i) => i.submitted);
  const reviewed = data.map((i) => i.reviewed);
  const approved = data.map((i) => i.approved);
  const rejected = data.map((i) => i.rejected);
  const portfolio = data.map((i) => i.portfolio);

  const app: any = {};
  type EChartsOption = echarts.EChartsOption;

  const myChart = echarts.getInstanceByDom(
    document.getElementById(chartId) as HTMLElement
  );
  // const option: EChartsOption;

  const posList = [
    "left",
    "right",
    "top",
    "bottom",
    "inside",
    "insideTop",
    "insideLeft",
    "insideRight",
    "insideBottom",
    "insideTopLeft",
    "insideTopRight",
    "insideBottomLeft",
    "insideBottomRight",
  ] as const;

  app.configParameters = {
    rotate: {
      min: -90,
      max: 90,
    },
    align: {
      options: {
        left: "left",
        center: "center",
        right: "right",
      },
    },
    verticalAlign: {
      options: {
        top: "top",
        middle: "middle",
        bottom: "bottom",
      },
    },
    position: {
      options: posList.reduce(function(map, pos) {
        map[pos] = pos;
        return map;
      }, {} as Record<string, string>),
    },
    distance: {
      min: 0,
      max: 100,
    },
  };

  app.config = {
    rotate: 90,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottom",
    distance: 15,
    onChange: function() {
      const labelOption: BarLabelOption = {
        rotate: app.config.rotate as BarLabelOption["rotate"],
        align: app.config.align as BarLabelOption["align"],
        verticalAlign: app.config
          .verticalAlign as BarLabelOption["verticalAlign"],
        position: app.config.position as BarLabelOption["position"],
        distance: app.config.distance as BarLabelOption["distance"],
      };
      myChart?.setOption<echarts.EChartsOption>({
        series: [
          {
            label: labelOption,
          },
          {
            label: labelOption,
          },
          {
            label: labelOption,
          },
          {
            label: labelOption,
          },
        ],
      });
    },
  };

  type BarLabelOption = NonNullable<echarts.BarSeriesOption["label"]>;

  const labelOption: BarLabelOption = {
    show: true,
    position: app.config.position as BarLabelOption["position"],
    distance: app.config.distance as BarLabelOption["distance"],
    align: app.config.align as BarLabelOption["align"],
    verticalAlign: app.config.verticalAlign as BarLabelOption["verticalAlign"],
    rotate: app.config.rotate as BarLabelOption["rotate"],
    formatter: "{c}  {name|{a}}",
    fontSize: 16,
    rich: {
      name: {},
    },
  };
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: [
        "Created",
        "Portfolio",
        "Submitted",
        "Reviewed",
        "Approved",
        "Rejected",
      ],
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: data.map((i) => format(new Date(i.date), "dd/MM/yyyy")),
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    color: [
      "#1976d2",
      "#3f51b5",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#f44336",
      "#d5ceeb",
    ],
    series: [
      {
        name: "Created",
        type: "line",
        barGap: 0,

        emphasis: {
          focus: "series",
        },
        data: created,
      },
      {
        name: "Portfolio",
        type: "line",
        emphasis: {
          focus: "series",
        },
        data: portfolio,
      },
      {
        name: "Submitted",
        type: "line",
        emphasis: {
          focus: "series",
        },
        data: submitted,
      },
      {
        name: "Reviewed",
        type: "line",
        emphasis: {
          focus: "series",
        },
        data: reviewed,
      },
      {
        name: "Approved",
        type: "line",
        emphasis: {
          focus: "series",
        },
        data: approved,
      },
      {
        name: "Rejected",
        type: "line",
        emphasis: {
          focus: "series",
        },
        data: rejected,
      },
    ],
  };
}
